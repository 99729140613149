<template>
  <a-card :bordered="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="大区事务所">
              <a-cascader
                :options="dealerList"
                v-model="regionalOffice"
                change-on-select
                placeholder="请选择大区/事务所"
                @change="onChange"
                :field-names="{ label: 'title', value: 'id', children: 'children' }"
              />
            </a-form-model-item>
          </a-col>
<!--          <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--            <a-form-model-item label="打款人">-->
<!--              <a-input v-model="searchData.lastPayer" placeholder="请输入最后打款人" allowClear></a-input>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
<!--          <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--            <a-form-model-item label="打款金额">-->
<!--              <a-input-number-->
<!--                v-model="searchData.lastMoney"-->
<!--                placeholder="请输入最后打款金额"-->
<!--                style="width: 100%"-->
<!--                allowClear-->
<!--              ></a-input-number>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
<!--          <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--            <a-form-model-item label="打款时间">-->
<!--              <DateRange :startTime.sync="searchData.startTime" :endTime.sync="searchData.endTime"></DateRange>-->
<!--            </a-form-model-item>-->
<!--          </a-col>-->
          <a-col :xxl="6" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="query()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button v-if="isCheck" type="primary" @click="toHandler('check')" icon="copy">查看</a-button>
        <a-button v-if="isRecharge" type="primary" @click="toHandler('recharge')" icon="pay-circle">充值</a-button>
        <downLoad
          v-if="isExport"
          method="get"
          api="/api/dealer/dealer/dealerPayInfo/excelExportPlayInfo"
          :params="searchData"
          name="经销商余额.xls"
          >导出</downLoad
        >
      </div>
      <a-table
        :loading="tableLoading"
        :rowKey="(record) => record.id"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        @expandedRowsChange="handlerCollspan"
        @expand="zi"
        :customRow="changeTableRow"
        :row-selection="rowSelection"
        :columns="columns"
        :expandedRowKeys="expandedRowKeys"
        ref="table"
      >
        <!-- 展开行 -->
        <div slot="expandedRowRender" class="collspan">
          <a-spin :spinning="spinning" />
          <p v-for="(item, index) in collspanData" :key="index" class="collspan-article">
            <span class="collspan-article-item1">{{ item.description1 }}</span>
            <span>{{ item.description2 }}</span>
            <span>{{ item.description3 }}</span>
            <span>{{ item.description4 }}</span>
            <span>{{ item.description5 }}</span>
            <span>{{ item.description6 }}</span>
          </p>
        </div>
        <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
        <!-- 欠款 -->
        <span slot="arrears" slot-scope="text, row">
          <span @click.stop="">
            <a href="#" class="TextRed" @click="handlerTableClick('TextRed', 'arrears', row)">{{
              text | formatMoney
            }}</a>
          </span>
        </span>
        <!-- 可使用余额 -->
        <span slot="canUseAmount" slot-scope="text">
          <span @click.stop="">
            <a href="#" class="DarkGreen" @click="handlerTableClick('TextRed')">{{ text | formatMoney }}</a>
          </span>
        </span>
        <!-- 资金余额 -->
        <span slot="amount" slot-scope="text, row">
          <span @click.stop="">
            <a class="ShallowGreen" @click="handlerTableClick('ShallowGreen', 'amount', row)" href="#">{{
              text | formatMoney
            }}</a>
          </span>
        </span>
        <!-- 信用额度 -->
        <span slot="limitAmount" slot-scope="text, row">
          <span @click.stop="">
            <a class="ShallowGreen" @click.stop="handlerTableClick('ShallowGreenXY', 'limitAmount', row)" href="#">{{
              text | formatMoney
            }}</a>
          </span>
        </span>
        <!-- 折扣余额 -->
        <span slot="rebateAmount" slot-scope="text, row">
          <span @click.stop="">
            <a class="ShallowSkyBlue" @click="handlerTableClick('ShallowGreenZK', 'rebateAmount', row)" href="#">{{
              text | formatMoney
            }}</a>
          </span>
        </span>
        <!-- 查看对账单 -->
        <span slot="operation" slot-scope="row">
          <span @click.stop="">
            <a href="#" class="DarkBule" @click="handlerTableClick('DarkBule', 'operation', row)">对账单</a>
          </span>
        </span>
      </a-table>
    </div>
    <FinanceReceiptEditModal ref="FinanceReceiptEditModal" @reload="reset"></FinanceReceiptEditModal>
  </a-card>
</template>

<script>
import FinanceReceiptEditModal from '@/views/finance_receipt/components/FinanceReceiptEditModal.vue'
import { checkPermission } from '@/utils/permissions'
import { columns } from './thead/columns'
const qs = require('qs')
export default {
  name: 'DealerBalance',
  components: {
    FinanceReceiptEditModal,
  },

  data() {
    return {
      columns: columns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      isCheck: checkPermission('dealer:balance:check'),
      isRecharge: checkPermission('dealer:balance:recharge'),
      isExport: checkPermission('dealer:balance:export'),
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      dealerList: [],
      regionalOffice: [],
      collspanData: [],
      expandedRowKeys: [],
      spinning: false,
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        type: 'radio',
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },
  created() {
    this.getList()
  },
  methods: {
    zi(expanded, record) {
      if (this.expandedRowKeys.length > 0) {
        let index = this.expandedRowKeys.indexOf(record.id)
        if (index > -1) {
          this.expandedRowKeys.splice(index, 1)
        } else {
          this.expandedRowKeys.splice(0, this.expandedRowKeys.length)
          this.expandedRowKeys.push(record.id)
        }
      } else {
        this.expandedRowKeys.push(record.id)
      }
    },
    handlerCollspan(expandedRowKeys) {
      this.spinning = true
      const index = expandedRowKeys.length
      const dealerId = expandedRowKeys[index - 1]
      if (dealerId) {
        this.axios
          .get(`/api/dealer/dealer/dealerPayInfo/getPayInfoDetailByDealerId/${dealerId}`)
          .then(({ code, body, mag }) => {
            if (code === 200) {
              this.collspanData = body
              this.spinning = false
            }
          })
          .finally(() => (this.spinning = false))
      }
    },
    handlerTableClick(type, name, row) {
      // 信用额度
      // if (name === 'limitAmount') {
      //   // 路由传参
      //   this.$router.push({
      //     name: 'aquota_admin',
      //     params: { dealerName: row.dealerName },
      //   })
      // }
      // // 资金余额
      // if (name === 'amount') {
      //   this.$refs.DealerBalanceDetailed.setRowData(row)
      // }
      // // 对账单
      // if (name === 'operation') {
      //   // 路由传参
      //   console.log(JSON.parse(JSON.stringify(row)), '对账单')
      //   this.$router.push({
      //     name: 'accountStatementList',
      //     params: { dealerName: row.dealerName },
      //   })
      // }
      // // 欠款
      // if (name === 'arrears') {
      //   // 路由传参
      //   this.$router.push({
      //     name: 'aquota_admin',
      //     params: { dealerName: row.dealerName },
      //   })
      // }
      // // 折扣金额
      // if (name === 'rebateAmount') {
      //   // 路由传参
      //   this.$router.push({
      //     name: 'rebate',
      //     params: { dealerName: row.dealerName },
      //   })
      // }
      if (type === 'TextRed') {
        if (this.$refs.QKAccountInfo) {
          // this.$refs.QKAccountInfo.init()
        }
      }
      if (type === 'DarkGreen') {
      }
      if (type === 'ShallowGreen') {
        if (this.$refs.ZJAccountInfo) {
          // this.$refs.ZJAccountInfo.init()
        }
      }
      if (type === 'ShallowGreenZK') {
        if (this.$refs.ZKAccountInfo) {
          // this.$refs.ZKAccountInfo.init()
        }
      }
      if (type === 'ShallowGreenXY') {
        if (this.$refs.XYAccountInfo) {
          // this.$refs.XYAccountInfo.init()
        }
      }
      if (type === 'DarkBule') {
        if (this.$refs.overview) {
          // this.$refs.overview.setRowData()
        }
      }
    },
    getList() {
      this.tableLoading = true
      let obj = {
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
        ...this.searchData,
      }
      this.axios
        .get('/api/dealer/dealer/dealerPayInfo/list', { params: obj })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))

      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },
    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList()
    },
    toHandler(name) {
      if (this.selectedRows.length <= 0) {
        return this.$message.warning('请至少选择一条数据')
      }
      if (name == 'check') {
        this.$refs.DealerBalanceDetailed.setRowData(this.selectedRows[0])
      } else if (name == 'recharge') {
        console.log(JSON.parse(JSON.stringify(this.selectedRows)), '充值')
        this.$refs.FinanceReceiptEditModal.setRowData(this.selectedRows[0], 'recharge')
      }
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },

    // 查询
    query() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getList()
    },

    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      this.getList()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-table-thead > tr > th.headerYellow {
  background-color: #fff2cc;
}
::v-deep .ant-table-thead > tr > th.headerTextRed {
  color: red;
  font-weight: 600;
}
::v-deep .ant-table-thead > tr > th.centerGreen {
  background-color: rgb(139, 247, 175);
  font-weight: 600;
}
::v-deep .ant-table-thead > tr > th.headerTextDarkGreen {
  color: #5c9833;
  font-weight: 600;
}
::v-deep .ant-table-thead > tr > th.headerTextShallowGreen {
  color: #87d554;
  font-weight: 600;
}
::v-deep .ant-table-thead > tr > th.centerBlue {
  background-color: skyblue;
  font-weight: 600;
}
::v-deep .ant-table-thead > tr > th.headerTextBlue {
  color: skyblue;
  font-weight: 600;
}
.TextRed,
.ShallowGreen,
.DarkBule,
.ShallowSkyBlue {
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
.TextRed {
  color: red;
}
.ShallowGreen {
  color: #87d554;
}
.DarkBule {
  color: #4472d8;
}
.DarkGreen {
  font-weight: 600;
  color: #5c9833;
}
.ShallowSkyBlue {
  color: skyblue;
}
.collspan {
  display: flex;
  flex-direction: column;
  // line-height: 1rem;
  // padding-top: 1rem;
  .collspan-article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px dashed #e8e8e8;
  }
  .collspan-article :nth-child(n + 2) {
    display: block;
    margin-left: 1%;
    width: 8rem;
    min-width: 6.25rem;
  }
  .collspan-article-item1 {
    padding-left: 6%;
    width: 21.875rem;
    display: block;
  }
}
</style>